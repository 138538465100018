import {SettingsParamType, createSettingsParams} from '@wix/tpa-settings'
import {
  EVENTS_PER_PAGE_DEFAULT,
  EVENT_FILTER_TYPE,
  EVENT_SORT_ORDER,
  FEATURED_EVENT_DISPLAY_STRATEGY,
  LIST_DISPLAY_STRATEGY,
  LIST_STATUS_FILTER,
} from '@wix/wix-events-commons-statics'
import {EMPTY_CATEGORY_FILTER} from '../../commons/constants/settings'
import {Override} from '../../commons/types/utils'

export interface ISettingsTexts {
  time: SettingsParamType.Text
  location: SettingsParamType.Text
  RSVPTitle: SettingsParamType.Text
  /**
   * @deprecated
   */
  registrationClosedButtonText: SettingsParamType.Text
  multiRsvpClosedBtnText: SettingsParamType.Text
  multiTicketedClosedBtnText: SettingsParamType.Text
  oneButton: SettingsParamType.Text
  noRegistrationBtnText: SettingsParamType.Text
  /**
   * @deprecated
   */
  listButtonText: SettingsParamType.Text
  multiRsvpButtonText: SettingsParamType.Text
  multiTicketedButtonText: SettingsParamType.Text
  multiNoRegButtonText: SettingsParamType.Text
  todayButtonText: SettingsParamType.Text
  listGeneralTitle: SettingsParamType.Text
  soldOutRibbonText: SettingsParamType.Text
  joinWaitlistRibbonText: SettingsParamType.Text
  rsvpClosedRibbonText: SettingsParamType.Text
  membershipRibbonText: SettingsParamType.Text
  recurringRibbonText: SettingsParamType.Text
  moreInfo: SettingsParamType.Text
  lessInfo: SettingsParamType.Text
}

export interface ISettingsNumbers {
  filterType: SettingsParamType.Number
  sortOrder: SettingsParamType.Number
  eventsPerPage: SettingsParamType.Number
  featuredEventDisplayStrategy: SettingsParamType.Number
  listDisplayStrategy: SettingsParamType.Number
  listStatusFilter: SettingsParamType.Number
}

export interface ISettingsStrings {
  categoryId: SettingsParamType.String
  featuredEventId: SettingsParamType.String
  featuredCategoryId: SettingsParamType.String
}

export interface ISettingsBooleans {
  featuredEvent: SettingsParamType.Boolean
}

export interface ISettingsParams extends ISettingsNumbers, ISettingsTexts, ISettingsStrings, ISettingsBooleans {}

export type WidgetComponentTextSettings = Override<ISettingsTexts, string>

const settingsParams = createSettingsParams<ISettingsParams>({
  time: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('whenLabel'),
  },
  location: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('whereLabel'),
  },
  RSVPTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('RSVPTitle'),
  },
  registrationClosedButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('registrationClosedButtonText'),
  },
  multiRsvpClosedBtnText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  multiTicketedClosedBtnText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  oneButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('oneButtonText'),
  },
  noRegistrationBtnText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('viewer_listNoRegButtonText'),
  },
  listButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  multiRsvpButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t, getSettingParamValue}) =>
      getSettingParamValue(settingsParams.listButtonText) || t('listRSVPButtonText'),
  },
  multiTicketedButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t, getSettingParamValue}) =>
      getSettingParamValue(settingsParams.listButtonText) || t('listTicketsButtonText'),
  },
  multiNoRegButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('viewer_listNoRegButtonText'),
  },
  todayButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('todayButtonText'),
  },
  listGeneralTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('listGeneralTitle'),
  },
  soldOutRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  joinWaitlistRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  rsvpClosedRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  membershipRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  recurringRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  moreInfo: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('moreInfoToggle'),
  },
  lessInfo: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('lessInfoToggle'),
  },
  filterType: {
    type: SettingsParamType.Number,
    getDefaultValue: () => EVENT_FILTER_TYPE.UPCOMING_AND_PAST,
  },
  categoryId: {
    type: SettingsParamType.String,
    getDefaultValue: undefined,
  },
  sortOrder: {
    type: SettingsParamType.Number,
    getDefaultValue: () => EVENT_SORT_ORDER.CHRONOLOGICAL,
  },
  eventsPerPage: {
    type: SettingsParamType.Number,
    getDefaultValue: () => EVENTS_PER_PAGE_DEFAULT,
  },
  featuredEvent: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  featuredEventDisplayStrategy: {
    type: SettingsParamType.Number,
    getDefaultValue: () => FEATURED_EVENT_DISPLAY_STRATEGY.CUSTOM,
  },
  featuredEventId: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
  },
  featuredCategoryId: {
    type: SettingsParamType.String,
    getDefaultValue: () => null,
  },
  listDisplayStrategy: {
    type: SettingsParamType.Number,
    getDefaultValue: ({getSettingParamValue}) => {
      const filterType = getSettingParamValue(settingsParams.filterType)
      const categoryId = getSettingParamValue(settingsParams.categoryId)

      if (filterType === EVENT_FILTER_TYPE.MANUAL) {
        return LIST_DISPLAY_STRATEGY.CUSTOM
      }

      if (categoryId && categoryId !== EMPTY_CATEGORY_FILTER) {
        return LIST_DISPLAY_STRATEGY.CATEGORY
      }

      return LIST_DISPLAY_STRATEGY.ALL_EVENTS
    },
  },
  listStatusFilter: {
    type: SettingsParamType.Number,
    getDefaultValue: ({getSettingParamValue}) => {
      const filterType = getSettingParamValue(settingsParams.filterType) as EVENT_FILTER_TYPE

      if (filterType === EVENT_FILTER_TYPE.MANUAL) {
        return LIST_STATUS_FILTER.UPCOMING_AND_PAST
      }

      return filterType
    },
  },
})

export default settingsParams
