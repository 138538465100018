import {IStyleParam, wixColorParam} from '@wix/tpa-settings'
import {LIST_LAYOUT, MOBILE_LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {GetDefaultValueProps, getDefaultFont} from '../../../../../settings-commons/utils/style'
import {widgetStyles} from '../../../stylesParams'
import {isCards, isCompactMobile, isGridMobile, isList, isNarrow, isSideBySide} from '../utils'
import {ADDITIONAL_MOBILE_LIST_LAYOUT, DEFAULT_FONT_STYLE, WidgetText} from './constants'
import {WidgetFontDefault, cardsFontDefaults, commonFontDefaults} from './defaults'
import {FontConfig, StudioParamsMap} from './mapper'

interface GetFontConfigParams {
  params: GetDefaultValueProps
  widgetText: WidgetText
  map: StudioParamsMap
}

interface GetWidgetFontDefaultParams extends GetFontConfigParams {
  hover?: boolean
  defaultParam?: IStyleParam
}

export const getFontConfig = ({params, widgetText, map}: GetFontConfigParams): FontConfig => {
  const {getStyleParamValue, isMobile, dimensions} = params
  const width = dimensions?.width
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT

  if (isNarrow(listLayout, width, isMobile)) {
    return map.mobile[MOBILE_LIST_LAYOUT.FULL][widgetText]
  }

  if (isMobile) {
    const mobileListLayout = getStyleParamValue(widgetStyles.mobileListLayout) as MOBILE_LIST_LAYOUT

    if (isGridMobile(mobileListLayout, listLayout)) {
      return map.mobile[ADDITIONAL_MOBILE_LIST_LAYOUT.GRID][widgetText]
    }

    return map.mobile[mobileListLayout]?.[widgetText]
  }

  return map.desktop[listLayout]?.[widgetText]
}

export const getWidgetFontDefault = ({params, widgetText, hover, defaultParam, map}: GetWidgetFontDefaultParams) => {
  const {getStyleParamValue, isMobile} = params
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT

  const defaults = (isCards(listLayout) ? cardsFontDefaults : commonFontDefaults)[widgetText]
  const defaultFontSize = getDefaultFontSize(defaults, isMobile, hover)

  const {font, size, hoverFont, hoverSize, staticFontSize, hoverStaticFontSize, style} =
    getFontConfig({
      params,
      widgetText,
      map,
    }) ?? {}
  const [fontParam, sizeParam, staticSize] = hover
    ? [hoverFont, hoverSize, hoverStaticFontSize]
    : [font, size, staticFontSize]
  const fontValue = fontParam && getStyleParamValue(fontParam)
  const sizeValue = staticSize ?? (sizeParam && getStyleParamValue(sizeParam))

  if (fontValue) {
    const {value: _value, preset: _preset, ...rest} = fontValue

    return sizeValue
      ? getDefaultFont(defaults.preset, {...rest, size: sizeValue, style: {...rest.style, ...style}})(params)
      : fontValue
  }

  if (hover && defaultParam) {
    const defaultParamValue = getStyleParamValue(defaultParam)
    const {value: _value, preset: _preset, ...rest} = defaultParamValue

    return sizeValue
      ? getDefaultFont(defaults.preset, {...rest, size: sizeValue, style: {...rest.style, ...style}})(params)
      : defaultParamValue
  }

  return getDefaultFont(defaults.preset, {
    size: sizeValue ?? defaultFontSize,
    style: {...DEFAULT_FONT_STYLE, ...style},
  })(params)
}

const getDefaultFontSize = (defaults: WidgetFontDefault, isMobile: boolean, hover?: boolean) => {
  const {fontSize, mobileFontSize, hoverFontSize, hoverMobileFontSize} = defaults

  if (isMobile) {
    return hover ? hoverMobileFontSize : mobileFontSize
  }

  return hover ? hoverFontSize : fontSize
}

export const getWidgetFontColorDefault = ({
  params,
  widgetText,
  hover,
  defaultParam,
  map,
}: GetWidgetFontDefaultParams) => {
  const {getStyleParamValue} = params
  const {color, hoverColor} = getFontConfig({params, widgetText, map}) ?? {}
  const colorParam = hover ? hoverColor : color
  const colorValue = colorParam && getStyleParamValue(colorParam)

  if (colorValue) {
    return colorValue
  }

  if (hover && defaultParam) {
    return getStyleParamValue(defaultParam)
  }

  return wixColorParam('color-5')(params)
}

export const getSocialShareDefault = (params: GetDefaultValueProps) => {
  const {getStyleParamValue} = params
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT

  if (isCards(listLayout) || isSideBySide(listLayout)) {
    return getStyleParamValue(widgetStyles.listHeaderTitleColor)
  }
  if (isList(listLayout)) {
    return getStyleParamValue(widgetStyles.listContentTextColor)
  }

  return wixColorParam('color-5')(params)
}

interface GetWidgetButtonDefaultFontParams {
  params: GetDefaultValueProps
  fontParam: IStyleParam
  mobileSizeParam: IStyleParam
  mobileCompactSizeParam: IStyleParam
}

export const getWidgetButtonDefaultFont = ({
  params,
  fontParam,
  mobileSizeParam,
  mobileCompactSizeParam,
}: GetWidgetButtonDefaultFontParams) => {
  const {getStyleParamValue, isMobile} = params
  const font = getStyleParamValue(fontParam)
  const mobileSize = getStyleParamValue(mobileSizeParam)
  const mobileCompactSize = getStyleParamValue(mobileCompactSizeParam)

  if (isMobile) {
    const {value: _value, preset: _preset, ...rest} = font
    const mobileLayout = getStyleParamValue(widgetStyles.mobileListLayout) as MOBILE_LIST_LAYOUT
    const size = isCompactMobile(mobileLayout) ? mobileCompactSize : mobileSize

    return getDefaultFont('Body-M', {...rest, size})(params)
  }

  return font
}
